<template>
  <div class="box has-max-width">
    <h3 class="title is-5 push-bottom-xxs">{{ $t('profile.settings.newsletter.title') }}</h3>
    <p>{{ $t('profile.settings.newsletter.helpText') }}</p>
    <p>{{ $t('profile.settings.newsletter.helpText2') }}</p>

    <form v-if="isPopulated" @submit.prevent="update">
      <FormField>
        <Checkbox
          v-model="settings.newsletter"
          :disabled="isLoading"
          :label="$t('profile.settings.newsletter.subtitle')">
        </Checkbox>
      </FormField>

      <div class="field is-grouped">
        <div class="flex align-center">
          <div class="control">
            <button :class="{ 'is-loading': isLoading }" class="button is-primary">
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isPopulated: false
    }
  },

  static () {
    return {
      settings: {}
    }
  },

  computed: {
    profile () {
      return this.$store.getters['profile/data']
    }
  },

  mounted () {
    this.populate()
  },

  methods: {
    populate () {
      if (this.profile) {
        Object.assign(this.settings, this.profile.settings)

        this.isPopulated = true
      }
    },

    async update () {
      this.isLoading = true

      try {
        await this.$store.dispatch('profile/update', {
          userId: this.profile.userId,
          settings: {
            newsletter: this.settings.newsletter,
            notifications: this.settings.notifications
          }
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.isLoading = false
    }
  }
}
</script>
